/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  StyleSheet, Text, TextInput, View,
} from 'react-native';
import {
  Controller, FieldError, FieldValues,
  FieldPath, FieldPathValue, useFormContext,
} from 'react-hook-form';

import Style from '../../style';
import FieldErrorMsg from './FieldErrorMsg';

// #Ref: https://react-hook-form.com/ts
type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  name: TName,
  caption?: string,
  disabled?: boolean,
  errIconAlign?: 'left' | 'right',
  textInputRef?: React.RefObject<TextInput>, // .focus() only works for TextInput
  render: (props: {
    value: FieldPathValue<TFieldValues, TName>
    onChange: (event: any) => void;
    onBlur: (event: any) => void;
  }) => React.ReactElement;
};

const FormInput = <T extends FieldValues>({
  name, caption, disabled,
  errIconAlign, render, textInputRef,
}: Props<T>) => {
  const { control, formState: { errors } } = useFormContext<T>();
  const error = errors[name] as FieldError | undefined;
  const opacity = disabled ? 0.7 : 1.0;

  return (
    <View style={[styles.wrapper, { opacity }]}>
      {caption && <Text style={styles.caption} selectable={false}>{caption}</Text>}
      <View>
        <Controller
          name={name}
          control={control}
          render={({ field }) => render(field)}
        />
        {error && errIconAlign
          && (
            <View
              style={[
                styles.errIcon,
                errIconAlign === 'left' && styles.errIconLeft,
              ]}
            >
              <Style.Icon.Warning fill={Style.Color.Tertiary} />
            </View>
          )}
      </View>
      {error && <FieldErrorMsg error={error} focus={() => textInputRef?.current?.focus()} />}
    </View>
  );
};

export default FormInput;

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    marginBottom: 18,
  },
  caption: {
    ...Style.Text.Small,
    color: Style.Color.Gray400,
    marginBottom: 5,
  },
  errIcon: {
    width: 36,
    height: 36,
    position: 'absolute',
    top: 2,
    right: 4,
    backgroundColor: Style.Color.White,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errIconLeft: {
    right: undefined,
    left: 4,
  },
});
