import React from 'react';
import { StyleSheet, Text } from 'react-native';
import {
  Radio, RadioGroup,
} from '@ui-kitten/components';
import _ from 'lodash';

import Style from '../../style';
import FormInput from './FormInput';

type Props = {
  name: string,
  radioItems: string[][],
  caption?: string,
  disabled?: boolean,
};

const CRadioGroup = ({
  name, radioItems, caption, disabled = false,
}: Props) => (
  <FormInput
    name={name}
    disabled={disabled}
    caption={caption}
    render={({ value, onChange }) => (
      <RadioGroup
        style={styles.radioGroup}
        selectedIndex={_.findIndex(radioItems, ([code]) => code === value)}
        onChange={(index) => {
          onChange(radioItems[index][0]);
        }}
      >
        {_.map(radioItems, ([code, label]) => (
          <Radio key={code}>
            {(evaProps) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <Text {...evaProps} style={styles.radioButtonText}>
                {label}
              </Text>
            )}
          </Radio>
        ))}
      </RadioGroup>
    )}
  />
);

export default CRadioGroup;

const styles = StyleSheet.create({
  radioGroup: {
    marginLeft: 20,
  },
  radioButtonText: {
    ...Style.Text.Normal,
    color: Style.Color.Black,
    marginLeft: 14,
  },
});
