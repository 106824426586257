import React, { forwardRef, Ref, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import _ from 'lodash';
import { useNavigation } from '@react-navigation/native';

import { RootStoreContext } from '../../stores/RootStore';
import Style from '../../style';
import I18n from '../../i18n';
import Section from '../Section';
import LoadingIndicator from '../LoadingIndicator';
import { Coaching, Invoice, RefObject } from '../../types';
import { isCloseToBottom } from '../../util/helpers';
import { fetchInvoiceDetails, fetchInvoicePdf, fetchInvoices } from '../../api/invoices';
import EmptyListHint from '../EmptyListHint';
import InvoiceRow, { ButtonOptions, SelectedOptions } from './InvoiceRow';
import { InvoiceStatus } from '../../types/Invoices';
import ModalView from '../ModalView';
import InvoiceModal from './InvoiceModal';
import CoachingModal from '../coachings/CoachingModal';
import { getBrowserInfo } from '../HeaderBar';
import { fetchCoachingDetails } from '../../api/Coachings';
import { NavigationProps } from '../../navigation/RouteParamList';

const LIMIT = 10;

const getSecIcon = (status: InvoiceStatus) => {
  if (status === 'pending') return Style.Icon.TrayArrowDown;
  if (status === 'disputed') return Style.Icon.ReceiptX;
  if (status === 'accepted') return Style.Icon.CheckSquare;
  if (status === 'paid') return Style.Icon.Coins;
  return Style.Icon.TrayArrowDown;
};

interface ISProps {
  status: InvoiceStatus;
  resetOtherComponent: (name: InvoiceStatus) => void;
  highlightedInvoiceId?: string;
}

const InvoicesSummary = forwardRef(
  ({ status, resetOtherComponent, highlightedInvoiceId }: ISProps, ref: Ref<RefObject>) => {
    useImperativeHandle(ref, () => ({ resetAllState }));
    const store = useContext(RootStoreContext);
    const myId = store.auth.userId;
    if (!myId) return null;
    const { compactView } = getBrowserInfo();
    const isCoach = store.auth.roles?.includes('coach');
    const [page, setPage] = useState(0);
    const [selectedInvoice, setSelectedInvoice] = useState<SelectedOptions>();
    const [selectedInvoiceCell, setSelectedInvoiceCell] = useState('');
    const allInvoicesRef = useRef<Invoice[]>([]);
    const navigation = useNavigation<NavigationProps>();

    const { data: allInvoicesForPage, error: errAllInvoices } = fetchInvoices({
      $sort: '-created',
      $offset: page,
      $limit: LIMIT,
      status,
    }).swr;

    if (allInvoicesForPage && 'httpStatus' in allInvoicesForPage) {
      const isAuthFail = store.uiState.checkError(allInvoicesForPage);
      if (isAuthFail) return null;
    }

    const { data: hlInvoiceDetails } = fetchInvoiceDetails(highlightedInvoiceId).swr;
    const { data: hlCoachingDetails } = fetchCoachingDetails(hlInvoiceDetails?.coachingId).swr;

    if (hlInvoiceDetails && hlInvoiceDetails.status === status) {
      allInvoicesRef.current = _.unionBy([hlInvoiceDetails], allInvoicesRef.current, 'id');
    }

    if (allInvoicesForPage) allInvoicesRef.current = _.unionBy(allInvoicesRef.current, allInvoicesForPage, 'id');

    const allInvoices = allInvoicesRef.current;

    useEffect(() => {
      if (hlInvoiceDetails && hlInvoiceDetails.status === status && hlCoachingDetails) {
        setSelectedInvoice({
          invoice: hlInvoiceDetails,
          coaching: hlCoachingDetails,
          activeButton: 'View Invoice',
        });
      }
    }, [hlInvoiceDetails]);

    useEffect(() => {
      // Update url if invoice modal is selected or changed
      navigation.navigate('Invoices',
        {
          ...(selectedInvoice?.activeButton === 'View Invoice' && { invoiceId: selectedInvoice.invoice.id }),
        });
    }, [selectedInvoice]);

    const updateOnStatusChange = (invoice: Invoice) => {
      _.remove(allInvoicesRef.current, (inv) => (
        inv.id === invoice.id && inv.status !== invoice.status));
      // Do not rerender, it will close the modal.
      // Data will be removed from the section list, once modal is closed.
    };

    const toggleActiveCell = (coachingId: string, isActiveCellCompact: boolean) => {
      if (!isActiveCellCompact) setSelectedInvoiceCell(coachingId);
      else setSelectedInvoiceCell('');
    };

    const onActionButtonPress = async (
      invoice: Invoice, coaching: Coaching, activeButton: ButtonOptions,
    ) => {
      if (['accepted', 'paid'].includes(invoice.status) && activeButton === 'View Invoice') {
        await fetchInvoicePdf(invoice.id);
        return;
      }
      setSelectedInvoice({ invoice, coaching, activeButton });
      resetOtherComponent(status);
    };

    const resetAllState = () => {
      setSelectedInvoice(undefined);
      setSelectedInvoiceCell('');
    };

    const closeModal = () => {
      setSelectedInvoice(undefined);
    };

    const renderInvoiceModal = () => {
      if (selectedInvoice?.activeButton !== 'View Invoice') return null;

      return (
        <ModalView
          refName="InvoiceModal"
          isVisible={!!selectedInvoice}
          closeModal={closeModal}
          style={compactView ? styles.modalCompact : styles.modal}
          drawer="right"
        >
          <InvoiceModal
            coaching={selectedInvoice.coaching}
            onStatusChange={updateOnStatusChange}
            closeModal={closeModal}
          />
        </ModalView>
      );
    };

    const renderCoachingModal = () => {
      if (selectedInvoice?.activeButton !== 'View Coaching') return null;

      return (
        <ModalView
          refName="CoachingModal"
          isVisible={!!selectedInvoice}
          closeModal={closeModal}
          drawer="right"
        >
          <CoachingModal
            coaching={selectedInvoice.coaching}
          />
        </ModalView>
      );
    };

    const renderInvoicesList = () => {
      if (!allInvoices) return null;
      const invoices = _.map(allInvoices, (invoice, index) => (
        <InvoiceRow
          key={invoice.id}
          invoice={invoice}
          selectedInvoice={selectedInvoice}
          selectedInvoiceCell={selectedInvoiceCell}
          isLastRow={(index === _.size(allInvoices) - 1)}
          onActionButtonPress={onActionButtonPress}
          toggleActiveCell={toggleActiveCell}
          resetOtherComponent={resetOtherComponent}
        />
      ));
      return (<View>{invoices}</View>);
    };

    if (allInvoices.length < 1) return null;

    return (
      <Section
        icon={getSecIcon(status)}
        title={I18n.t(`ui.invoices.secTitle.${isCoach ? 'receiver' : 'payer'}.${status}`)}
        key={`Invoice${status}`}
      >
        {allInvoices.length > 0 && (
          <View style={styles.container}>
            <ScrollView
              showsVerticalScrollIndicator={false}
              style={{ marginTop: 6 }}
              scrollEventThrottle={16}
              onScroll={(e) => {
                if (isCloseToBottom(e.nativeEvent)) {
                  setPage(allInvoicesRef.current.length);
                }
              }}
            >
              {renderInvoicesList()}
            </ScrollView>
          </View>
        )}
        <View style={{ height: (allInvoicesForPage && allInvoices.length === 0) ? 48 : 30 }}>
          {!(allInvoicesForPage || errAllInvoices) && <LoadingIndicator text={I18n.t('ui.invoices.loadingInvoices')} />}
          {(allInvoicesForPage || errAllInvoices) && allInvoices.length === 0 && (
            <EmptyListHint text={I18n.t('ui.invoices.emptyListHint')} />
          )}
        </View>
        {renderInvoiceModal()}
        {renderCoachingModal()}
      </Section>
    );
  },
);

InvoicesSummary.defaultProps = {
  highlightedInvoiceId: undefined,
};

export default InvoicesSummary;

const styles = StyleSheet.create({
  container: {
    maxHeight: 500,
  },
  item: {
    flexDirection: 'row',
    backgroundColor: Style.Color.White,
    borderLeftColor: Style.Color.Transparent,
    borderLeftWidth: 4,
    paddingLeft: 41,
    cursor: 'default',
  },
  itemCompact: {
    flexDirection: 'column',
    paddingLeft: 40,
    cursor: 'pointer',
    paddingBottom: 10,
  },
  itemHover: {
    borderLeftColor: Style.Color.Gray300,
  },
  itemActive: {
    borderLeftColor: Style.Color.Primary,
    borderLeftWidth: 4,
  },
  itemDivider: {
    borderBottomColor: Style.Color.Gray200,
    borderBottomWidth: 1,
    marginHorizontal: 28,
  },
  modal: {
    minHeight: 100,
  },
  modalCompact: {
    minHeight: 100,
  },

});
