import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';
import { StyleSheet, Text, View, ViewStyle } from 'react-native';
import _ from 'lodash';

import Style from '../../style';
import I18n, { currencyPos } from '../../i18n';
import { NOTE_RX, AMOUNT_RX } from '../../util/regex';
import TextInput from '../TextInput';
import ButtonSmall from '../ButtonSmall';

const tableColWs = [350, 100, 80, 150];
export const tableWidth = _.sum(tableColWs) + 10;
const getUniqueNumber = () => Date.now();

interface TRProps {
  col0: string | string[],
  col1?: string,
  col2?: string,
  col3: string,
  itemId?: string,
  title?: boolean,
  rowStyle?: ViewStyle,
  type?: 'text' | 'inputBox',
  submitCorrection?: (desc: string, amount: number, itemId?: string) => Promise<boolean>;
}

type CorrErr = {
  note: string,
  amount: string,
};

export type TableRowMethods = {
  pressSubmit: () => Promise<boolean>;
};

const TableRow = forwardRef(({
  col0, col1, col2, col3, itemId, title,
  rowStyle, type, submitCorrection }: TRProps, ref: Ref<TableRowMethods>) => {
  useImperativeHandle(ref, () => ({ pressSubmit }));
  const [correction, setCorrection] = useState('');
  const [isModified, setIsModified] = useState(false);
  const [textBoxKey, setTextBoxKey] = useState(getUniqueNumber());
  const [correctionErr, setCorrectionErr] = useState<CorrErr>();
  const inputs = Array.from(Array(2), () => React.createRef<TextInput>());
  const cellStyle = (col: number, align: 'left' | 'right' = 'right') => (title ? [styles.tableTitle, { width: tableColWs[col], textAlign: align }, rowStyle]
    : [styles.bodyDes, { width: tableColWs[col], textAlign: align }, rowStyle]);

  useEffect(() => {
    if (type !== 'inputBox') return;
    setCorrectionErr(undefined);
    if ((col0 === inputs?.[0]?.current?.text && col3 === inputs?.[1]?.current?.text)) {
      setIsModified(false);
    } else {
      setIsModified(true);
    }
  }, [correction]);

  const validateCorrection = () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const input of inputs) {
      if (!input.current?.checkAndComplain()) return false;
    }
    // Check dependency between both fields
    if (inputs?.[0]?.current?.text.length === 0
      && inputs?.[1]?.current?.text.length !== 0
      && inputs?.[1]?.current?.text !== '0'
    ) {
      setCorrectionErr({ note: I18n.t('error.corrNoteReq'), amount: '' });
      return false;
    }

    if (inputs?.[0]?.current?.text.length !== 0
      && inputs?.[1]?.current?.text.length === 0
    ) {
      setCorrectionErr({ note: '', amount: I18n.t('error.corrAmountReq') });
      return false;
    }

    setCorrectionErr(undefined);
    return true;
  };

  const pressCancel = () => {
    setTextBoxKey(getUniqueNumber());
    setCorrection('');
    setCorrectionErr(undefined);
  };

  const pressSubmit = async () => {
    const [desc, amount] = inputs.map((input) => input.current?.text || '');
    if (validateCorrection() && submitCorrection) {
      if (!isModified) {
        return true;
      }
      await submitCorrection(desc, Number(amount), itemId);
      return true;
    }
    return false;
  };

  if (type === 'inputBox') {
    return (
      <View style={styles.row}>
        <View style={cellStyle(0, 'left')}>
          <TextInput
            key={`note${textBoxKey}`}
            text={col0 as string}
            hint="Note"
            ref={inputs[0]}
            rx={NOTE_RX}
            errMsg={I18n.t('error.pleaseEnterValidNote')}
            boxStyle={styles.inputCorrection}
            optional
            onChange={(text) => setCorrection(text)}
          />
          {correctionErr?.note !== '' && <Text style={styles.errMsg}>{correctionErr?.note}</Text>}
        </View>
        <Text style={cellStyle(1)}>{col1}</Text>
        <Text style={cellStyle(2)}>{col2}</Text>
        <View style={cellStyle(3)}>
          <View style={styles.inputAmountView}>
            {(currencyPos() === 'left') && <Text style={styles.currencySymbol}>€</Text>}
            <View>
              <TextInput
                key={`amount${textBoxKey}`}
                text={col3}
                hint="0"
                ref={inputs[1]}
                rx={AMOUNT_RX}
                errMsg={I18n.t('error.pleaseEnterAValidAmount')}
                boxStyle={styles.inputAmount}
                errIconAlign="left"
                optional
                onChange={(text) => setCorrection(text)}
              />
            </View>
            {correctionErr?.amount !== '' && (
              <Text numberOfLines={1} style={styles.errMsg}>{correctionErr?.amount}</Text>)}
            {(currencyPos() === 'right') && <Text style={styles.currencySymbolRight}>€</Text>}
          </View>
          <View style={styles.buttonView}>
            {isModified && (
              <ButtonSmall
                key="cancel"
                onPress={pressCancel}
                title={I18n.t('ui.buttons.cancel')}
                icon="XCircle"
                buttonStyle={styles.button}
                wrapperStyle={styles.buttonWrapper}
                titleStyle={styles.buttonTitle}
              />
            )}
            {isModified && (
              <ButtonSmall
                key="Save"
                onPress={pressSubmit}
                title={I18n.t('ui.buttons.save')}
                icon="CheckCircle"
                buttonStyle={styles.button}
                wrapperStyle={styles.buttonWrapper}
                titleStyle={styles.buttonTitle}
              />
            )}
          </View>
        </View>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      {Array.isArray(col0) ? (
        <Text style={{ width: tableColWs[0] }}>
          <Text style={styles.bodyDes}>{col0[0]}</Text>
          <Text style={styles.additionalInfo}>{`\n${col0[1]}`}</Text>
        </Text>
      ) : <Text style={cellStyle(0, 'left')}>{col0}</Text>}
      <Text style={cellStyle(1)}>{col1}</Text>
      <Text style={cellStyle(2)}>{col2}</Text>
      <Text style={cellStyle(3)}>{col3}</Text>
    </View>
  );
});

TableRow.defaultProps = {
  col1: '',
  col2: '',
  itemId: undefined,
  title: false,
  type: 'text',
  rowStyle: undefined,
  submitCorrection: undefined,
};

export default TableRow;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingBottom: 5,
  },
  row: {
    flexDirection: 'row',
  },
  tableTitle: {
    ...Style.Text.NormalItalic,
    color: Style.Color.Black,
  },
  tableTitleRight: {
    ...Style.Text.NormalItalic,
    color: Style.Color.Black,
    textAlign: 'right',
  },
  bodyTitle: {
    ...Style.Text.Heading1,
    color: Style.Color.Black,
    marginBottom: 20,
  },
  bodyDes: {
    ...Style.Text.Normal,
    color: Style.Color.Black,
    marginBottom: 10,
  },
  bodyDesRight: {
    ...Style.Text.Normal,
    color: Style.Color.Black,
    marginBottom: 10,
    textAlign: 'right',
  },
  additionalInfo: {
    ...Style.Text.Small,
    color: Style.Color.Gray400,
  },
  inputCorrection: {
    borderColor: Style.Color.Gray300,
    outlineColor: Style.Color.Gray300,
    color: Style.Color.Gray600,
  },
  inputText: {
    ...Style.Text.Normal,
    color: Style.Color.Gray800,
  },
  inputAmountView: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  inputAmountRow: {
    flexDirection: 'row',
  },
  inputAmount: {
    width: 160,
    borderColor: Style.Color.Gray300,
    outlineColor: Style.Color.Gray300,
    color: Style.Color.Gray600,
    textAlign: 'right',
    alignSelf: 'flex-end',
    overflow: 'visible',
  },
  currencySymbol: {
    ...Style.Text.Normal,
    color: Style.Color.Black,
    marginRight: 5,
    marginTop: 10,
  },
  currencySymbolRight: {
    ...Style.Text.Normal,
    color: Style.Color.Black,
    marginLeft: 2,
    marginTop: 10,
  },
  errMsg: {
    position: 'absolute',
    width: '100%',
    top: 45,
    ...Style.Text.Tiny,
    color: Style.Color.Tertiary,
    textAlign: 'right',
    paddingRight: 10,
    overflow: 'visible',
  },
  buttonView: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    minHeight: 30,
  },
  button: {
    flexDirection: 'row',
    paddingLeft: 20,
  },
  buttonWrapper: {
    marginHorizontal: 0,
  },
  buttonTitle: {
    paddingLeft: 4,
  },
});
