import React, { useState, useContext } from 'react';
import {
  ImageBackground, StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import { Pressable } from 'react-native-web-hover';

import { capFirstLetter, MIN_SQUEEZE_WIDTH, openUrl } from '../util/helpers';
import Style from '../style';
import Box from '../components/Box';
import TextInput from '../components/TextInput';
import Button from '../components/Button';
import BoxErrorMessage from '../components/BoxErrorMessage';
import { RootStoreContext } from '../stores/RootStore';
import I18n from '../i18n';
import bgImg from '../../assets/img/img_bg_login.jpg';
import { fetchUser } from '../api/Users';
import { EMAIL_RX, PASS_RX } from '../util/regex';

const RESET_PASS_URL = 'https://support.thenextwe.com/en/forgot-password/';

const LoginScreen = () => {
  const store = useContext(RootStoreContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const inputs = Array.from(Array(2), () => React.createRef<TextInput>());
  // const refPswInput = useRef();

  const submit = async () => {
    // Make sure all inputs are valid
    // eslint-disable-next-line no-restricted-syntax
    for (const input of inputs) {
      if (!input.current?.checkAndComplain()) return;
    }
    // Sign in
    setError('');
    const [email, pass] = inputs.map((input) => input.current?.text || '');
    setLoading(true);
    try {
      await store.auth.logIn(email.trim(), pass);
      if (store.auth.userId) {
        const { data, error: errUser } = fetchUser(store.auth.userId).swr;
        if (data && 'httpStatus' in data) store.uiState.checkError(data);
        if (errUser) setError(errUser.toString());
      }
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message.toString());
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <ImageBackground source={bgImg} style={styles.wrapper}>
      <Box style={styles.loginBox}>
        <Pressable testID="LoginScreen.ForgotPswButton">
          {({ hovered }) => (
            <TouchableOpacity onPress={() => { openUrl(RESET_PASS_URL, true); }}>
              <Text style={[styles.link, hovered && styles.linkHovered]}>
                {I18n.t('ui.login.forgotPassword')}
              </Text>
            </TouchableOpacity>
          )}
        </Pressable>
        <Text style={styles.heading}>{I18n.t('ui.login.welcome')}</Text>
        <TextInput
          caption={I18n.t('ui.login.email')}
          hint="alice@example.com"
          rx={EMAIL_RX}
          errMsg={I18n.t('error.login.pleaseEnterValidEmail')}
          disabled={loading}
          ref={inputs[0]}
          testId="LoginScreen.EmailInput"
          submit={submit}
        />
        <TextInput
          caption={I18n.t('ui.login.password')}
          rx={PASS_RX}
          errMsg={I18n.t('error.login.passwordLengthWarning')}
          masked
          disabled={loading}
          ref={inputs[1]}
          testId="LoginScreen.PasswordInput"
          submit={submit}
        />
        <View style={styles.pad}>
          <Button onPress={submit} title={I18n.t('ui.login.signIn')} loading={loading} testId="LoginScreen.SignInButton" />
        </View>
        {!!error && <BoxErrorMessage msg={capFirstLetter(error)} />}
      </Box>
    </ImageBackground>
  );
};
export default LoginScreen;

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 10,
  },
  loginBox: {
    width: '100%',
    maxWidth: 424,
    minWidth: MIN_SQUEEZE_WIDTH,
  },
  link: {
    ...Style.Text.Small,
    color: Style.Color.Gray400,
    alignSelf: 'flex-end',
  },
  linkHovered: {
    color: Style.Color.Primary,
    cursor: 'pointer',
  },
  heading: {
    ...Style.Text.Heading1,
    color: Style.Color.Black,
    alignSelf: 'center',
    marginVertical: 20,
  },
  pad: {
    marginTop: 20,
    marginBottom: 8,
  },
});
