import React from 'react';
import { StyleSheet, View } from 'react-native';

import Button from '../Button';
import I18n from '../../i18n';
import { InvoiceStatus } from '../../types/Invoices';
import Style from '../../style';
import { tableWidth } from './TableRow';
import { getBrowserInfo } from '../HeaderBar';

interface IABProps {
  status: InvoiceStatus,
  isCoach: boolean,
  onPress: (status: InvoiceStatus) => void,
}

const InvoiceActionButtons = ({ status, isCoach, onPress }: IABProps) => {
  const { browser } = getBrowserInfo();
  const invoiceW = tableWidth + 60;
  const getButtonPadStyle = () => ([
    styles.buttonPad,
    { width: browser.width > invoiceW ? invoiceW : '100%' },
  ]);

  if (isCoach) {
    if (status === 'pending') {
      return (
        <View style={[getButtonPadStyle(), styles.buttonPadMany]}>
          <Button
            btnStyle={styles.button}
            btnStyleHovered={styles.buttonHovered}
            onPress={() => { onPress('disputed'); }}
            title={I18n.t('ui.buttons.dispute')}
          />
          <Button
            btnStyle={styles.button}
            btnStyleHovered={styles.buttonHovered}
            onPress={() => { onPress('accepted'); }}
            title={I18n.t('ui.buttons.reqPayment')}
          />
        </View>
      );
    }
    return null;
  }

  /* Buttons for OPS/supports */
  if (status === 'pending' || status === 'accepted') {
    return (
      <View style={getButtonPadStyle()}>
        <Button
          btnStyle={styles.button}
          btnStyleHovered={styles.buttonHovered}
          onPress={() => { onPress('paid'); }}
          title={I18n.t('ui.buttons.paid')}
        />
      </View>
    );
  }
  if (status === 'disputed') {
    return (
      <View style={getButtonPadStyle()}>
        <Button
          btnStyle={styles.button}
          btnStyleHovered={styles.buttonHovered}
          onPress={() => { onPress('pending'); }}
          title={I18n.t('ui.buttons.resolveReview')}
        />
      </View>
    );
  }

  return null;
};

export default InvoiceActionButtons;

const styles = StyleSheet.create({
  buttonPad: {
    justifyContent: 'flex-end',
    marginTop: 20,
    flexDirection: 'row',
    marginBottom: 20,
    paddingHorizontal: 5,
  },
  buttonPadMany: {
    justifyContent: 'space-between',
  },
  changeButton: {
    backgroundColor: Style.Color.White,
    borderWidth: 1,
    borderColor: Style.Color.Gray800,
  },
  changeButtonText: {
    ...Style.Text.Normal,
    color: Style.Color.Gray800,
  },
  changeButtonH: {
    backgroundColor: Style.Color.Primary,
    borderColor: Style.Color.Primary,
  },
  changeButtonTextH: {
    color: Style.Color.White,
  },
  button: {
    marginLeft: 4,
  },
  buttonHovered: {
    marginLeft: 4,
    backgroundColor: Style.Color.Primary,
  },
});
