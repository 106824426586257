export const EMAIL_RX = /\S+@\S+\.\S{2,}/;
export const PASS_RX = /^[ -~]{8,72}$/;
export const NAME_RX = /^(?!\s*$)[a-zA-ZÀ-ÿ\u4E00-\u9FCC\-. ']+$/u;
export const IBAN_RX = /^[A-Z\d]{15,34}$/;
export const VAT_RX = /^[A-Z\d-]{5,20}$/;
export const TAX_RX = /^[A-Z\d+-]{5,20}$/;
export const GENERAL_RX = /^[^\s].*/;
export const ZIPCODE_RX = /^[A-Z}\d\- ]{3,9}$/;
export const AMOUNT_RX = /^-?\d+(\.\d{1,2})?$/;
export const NOTE_RX = /^(?!\s*$).{3,}$/;
export const COUNTRY_RX = /^[a-zA-Z]{2}$/;
