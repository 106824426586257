import React, { useContext, useState } from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import { mutate } from 'swr';
import _ from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { RootStoreContext } from '../stores/RootStore';
import Style from '../style';
import I18n, { getCountryNames } from '../i18n';
import Button from './Button';
import { createBillingDetails, fetchBillingDetailsList, updateBillingDetailsItem } from '../api/BillingDetails';
import { SUCCESS } from '../util/helpers';
import ApiNotifBottom from './ApiNotifBottom';
import CTextInput from './customfields/CTextInput';
import { BillingInfoFormSchema } from '../util/validation';
import CRadioGroup from './customfields/CRadioGroup';
import CDropdown from './customfields/CDropdown';

type FormData = yup.InferType<typeof BillingInfoFormSchema>;

interface EPDProps {
  closeModal: () => void;
}

const EditBillingInfo = observer(({ closeModal }: EPDProps) => {
  const store = useContext(RootStoreContext);
  const myId = store.auth.userId;
  if (!myId) return null;
  const { data: billings } = fetchBillingDetailsList(myId).swr;
  const fetchBillingUrl = fetchBillingDetailsList(myId).url;
  if (billings && 'httpStatus' in billings) store.uiState.checkError(billings);
  const billing = billings?.[0];

  const [loading, setLoading] = useState(false);
  const [apiRes, setApiRes] = useState('');

  const countries = getCountryNames();
  const countryArr = Object.entries(countries);

  const taxModes = [
    ['taxable', I18n.t('ui.billings.taxMode.taxable')],
    ['nonTaxable', I18n.t('ui.billings.taxMode.nonTaxable')],
  ];

  const methods = useForm<FormData>({
    defaultValues: {
      firstName: billing?.firstName,
      lastName: billing?.lastName,
      companyName: billing?.companyName,
      street: billing?.street,
      zipCode: billing?.zipCode,
      city: billing?.city,
      state: billing?.state,
      country: billing?.country || 'DE',
      vatId: billing?.vatId,
      taxId: billing?.taxId,
      taxMode: billing?.taxMode || taxModes[0][0],
      bankIban: billing?.bankIban,
    },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    resolver: yupResolver(BillingInfoFormSchema),
  });

  const onSubmit = methods.handleSubmit((data: FormData) => save(data));

  const save = async (data: FormData) => {
    setApiRes('');
    const {
      bankIban, vatId, taxId, firstName, lastName,
      companyName, street, zipCode, city, state, country, taxMode,
    } = data;

    const body = _.pickBy({
      bankIban,
      vatId,
      taxId,
      firstName,
      lastName,
      companyName: companyName !== '' ? companyName : null,
      street: street || null,
      zipCode,
      city,
      state,
      country,
      taxMode,
    }, (value) => value !== '' && value !== undefined);

    if (!!_.size(vatId) === !!_.size(taxId)) {
      setApiRes(I18n.t('error.billings.vatOrTax'));
      setTimeout(() => { setApiRes(''); }, 4300);
      return;
    }

    setLoading(true);
    try {
      if (billing?.id) {
        const res = await updateBillingDetailsItem(myId, billing.id, body);
        if (res.id) await mutate(fetchBillingUrl, [res]);
      } else {
        const res = await createBillingDetails(myId, body);
        if (res.id) await mutate(fetchBillingUrl, [res]);
      }
      setApiRes(SUCCESS);
      setTimeout(() => { closeModal(); }, 2000);
    } catch (err) {
      if (err instanceof Error) {
        setApiRes(err.message.toString());
        setTimeout(() => { setApiRes(''); }, 4300);
      }
    } finally {
      setLoading(false);
    }
  };

  const editPaymentInfo = () => (
    <View style={styles.inputView}>
      <CTextInput
        name="bankIban"
        caption={I18n.t('ui.billings.bankIban')}
      />
      <CRadioGroup
        name="taxMode"
        caption={I18n.t('ui.billings.taxMode.title')}
        radioItems={taxModes}
      />
      <CTextInput
        name="vatId"
        caption={I18n.t('ui.billings.vatId')}
      />
      <CTextInput
        name="taxId"
        caption={`${I18n.t('other.or')} ${I18n.t('ui.billings.taxId')}`}
      />
    </View>
  );

  const editAddress = () => (
    <View style={styles.inputView}>
      <CTextInput
        name="firstName"
        caption={I18n.t('ui.personalData.firstName')}
      />
      <CTextInput
        name="lastName"
        caption={I18n.t('ui.personalData.lastName')}
      />
      <CTextInput
        name="companyName"
        caption={I18n.t('ui.billings.companyName')}
      />
      <CTextInput
        name="street"
        caption={I18n.t('ui.billings.street')}
      />
      <CTextInput
        name="zipCode"
        caption={I18n.t('ui.billings.zipCode')}
      />
      <CTextInput
        name="city"
        caption={I18n.t('ui.billings.city')}
      />
      <CTextInput
        name="state"
        caption={I18n.t('ui.billings.state')}
      />
      <CDropdown
        name="country"
        caption={I18n.t('ui.billings.country')}
        options={countryArr}
      />
    </View>
  );

  return (
    <View style={styles.container}>
      <ScrollView showsHorizontalScrollIndicator={false} style={styles.form}>
        <Text style={styles.header}>{I18n.t('ui.billings.edit')}</Text>
        <FormProvider {...methods}>
          <View style={{ alignItems: 'flex-start' }}>
            <Text style={styles.subHeader}>{I18n.t('ui.billings.paymentInfo')}</Text>
            {editPaymentInfo()}
            <Text style={styles.subHeader}>{I18n.t('ui.billings.address')}</Text>
            {editAddress()}
          </View>
          <View style={styles.buttonPad}>
            <Button
              btnStyleHovered={styles.btnSaveHovered}
              onPress={onSubmit}
              title={I18n.t('ui.buttons.save')}
              testId="BillingInfo.EditButton"
              disable={!methods.formState.isDirty}
              icon="PaperPlaneRight"
              loading={loading}
            />
          </View>
        </FormProvider>
      </ScrollView>
      {!!apiRes && <View style={styles.disableLayer} />}
      {!!apiRes && <ApiNotifBottom apiRes={apiRes} successText={I18n.t('other.updatedSuccessfully')} />}
    </View>
  );
});

export default EditBillingInfo;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignItems: 'flex-start',
    flex: 1,
    paddingHorizontal: 30,
    backgroundColor: Style.Color.White,
  },
  form: {
    width: '100%',
  },
  header: {
    ...Style.Text.Heading2,
    color: Style.Color.Black,
    textAlign: 'center',
    width: '100%',
    marginBottom: 25,
    zIndex: 10,
  },
  subHeader: {
    ...Style.Text.Heading3,
    color: Style.Color.Black,
    width: '100%',
    marginTop: 10,
    marginBottom: 15,
    zIndex: 10,
  },
  buttonPad: {
    width: '100%',
    height: 40,
    alignSelf: 'center',
    marginBottom: 24,
    marginTop: 24,
  },
  btnSaveHovered: {
    marginLeft: 4,
    backgroundColor: Style.Color.Primary,
  },
  spaceV: {
    marginBottom: 18,
  },
  inputView: {
    width: '100%',
  },
  notification: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  notificationText: {
    ...Style.Text.Normal,
    color: Style.Color.White,
    marginLeft: 10,
  },
  disableLayer: {
    backgroundColor: Style.Color.White,
    height: '100%',
    width: '100%',
    position: 'absolute',
    opacity: 0.5,
    borderRadius: 4,
  },
});
