import React, { useContext } from 'react';
import { StyleSheet, View } from 'react-native';
import { observer } from 'mobx-react-lite';

import { RootStoreContext } from '../stores/RootStore';
import ScreenLayout from './ScreenLayout';
import CallsSummary from '../components/home/CallsSummary';
import ClientsSummary from '../components/home/ClientsSummary';
import QualitySummary from '../components/home/QualitySummary';
import { getBrowserInfo } from '../components/HeaderBar';
import { getInitialRouteName } from '../navigation/AccessRules';
import RecentProjects from '../components/home/RecentProjects';

const HomeScreen = observer(() => {
  const store = useContext(RootStoreContext);
  const myId = store.auth.userId;
  if (!myId) return null;
  // In Routes.tsx, Linking.config.screens.home = '' is disabled,
  // so this code should not be executed.
  // Redirecting to other screen until Home page is finalized (Only admin should see it now)
  const route = `/${getInitialRouteName(store.auth.roles?.[0] || 'profile')}`;
  window.location.replace(route.toLowerCase());
  return null;

  const { compactView } = getBrowserInfo();

  return (
    <ScreenLayout>
      <View style={[styles.container, compactView && styles.centerAlign]}>
        <View style={[styles.summaryContainer, compactView && styles.summaryContainerCompact]}>
          <ClientsSummary />
          <CallsSummary />
          <QualitySummary />
        </View>
        <View style={[styles.summaryContainer, compactView && styles.summaryContainerCompact]}>
          <RecentProjects />
        </View>
      </View>
    </ScreenLayout>
  );
});
export default HomeScreen;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  summaryContainer: {
    flexDirection: 'row',
    paddingTop: 24,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  summaryContainerCompact: {
    flexDirection: 'column',
  },
  centerAlign: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});
