import React from 'react';
import {
  IndexPath, Select, SelectItem,
} from '@ui-kitten/components';
import _ from 'lodash';

import FormInput from './FormInput';

type Props = {
  name: string,
  options: string[][],
  caption?: string,
  disabled?: boolean,
};

const CDropdown = ({
  name, options, caption, disabled = false,
}: Props) => (
  <FormInput
    name={name}
    disabled={disabled}
    caption={caption}
    render={({ value, onChange }) => (
      <Select
        selectedIndex={new IndexPath(_.findIndex(options, ([code]) => code === value))}
        onSelect={(index) => {
          const selectedValue = options[(index as IndexPath).row]?.[0];
          onChange(selectedValue);
        }}
        value={_.find(options, ([code]) => code === value)?.[1]}
        style={{ backgroundColor: 'white' }}
        disabled={disabled}
      >
        {options.map(([code, label]) => (
          <SelectItem key={code} title={label} />
        ))}
      </Select>
    )}
  />
);

export default CDropdown;
