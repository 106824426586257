import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import { RouteParamList } from './RouteParamList';
import {
  HomeScreen, AvailabilitiesScreen, CallsScreen, NotFoundScreen,
  ProjectsScreen, ProjectDetailsScreen, CoachingsScreen,
  ProfileScreen,
  InvoicesScreen,
} from '../screens';
import { getInitialRouteName } from './AccessRules';

const screenOptionStyle = {
  headerShown: false,
};

const Stack = createStackNavigator<RouteParamList>();

const linking = {
  prefixes: [],
  config: {
    screens: {
      // Home: '',
      Availabilities: 'availabilities',
      Calls: 'calls',
      NotFound: '*',
      Projects: 'projects',
      ProjectDetails: 'projectDetails',
      Coachings: 'coachings',
      Profile: 'profile',
      Invoices: 'invoices',
    },
  },
};

interface Props {
  role: string;
}

const Routes = ({ role }: Props) => (
  <NavigationContainer linking={linking}>
    <Stack.Navigator
      screenOptions={screenOptionStyle}
      initialRouteName={getInitialRouteName(role)}
    >
      <Stack.Screen name="Home" component={HomeScreen} options={{ title: 'Home' }} />
      <Stack.Screen name="Availabilities" component={AvailabilitiesScreen} options={{ title: 'Availabilities' }} />
      <Stack.Screen name="Calls" component={CallsScreen} options={{ title: 'Calls' }} />
      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Not found' }} />
      <Stack.Screen name="Projects" component={ProjectsScreen} options={{ title: 'Projects' }} />
      <Stack.Screen name="ProjectDetails" component={ProjectDetailsScreen} options={{ title: 'Project Details' }} />
      <Stack.Screen name="Coachings" component={CoachingsScreen} options={{ title: 'Coachings' }} />
      <Stack.Screen name="Profile" component={ProfileScreen} options={{ title: 'Profile' }} />
      <Stack.Screen name="Invoices" component={InvoicesScreen} options={{ title: 'Invoices' }} />
    </Stack.Navigator>
  </NavigationContainer>
);

export default Routes;
