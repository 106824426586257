import useSWR from 'swr';
import { createFetcher, sendToServer } from './Requests';
import { BillingDetails, ErrorRes } from '../types';

export const fetchBillingDetailsList = (userId: string, query = {}) => {
  const { url, fetcher } = createFetcher(`/users/${userId}/billingDetails`, query);
  return { swr: useSWR<BillingDetails[], ErrorRes>(url, fetcher), url };
};

export const fetchBillingDetailsItem = (userId: string, detailsId: string) => {
  const { url, fetcher } = createFetcher(`/users/${userId}/billingDetails/${detailsId}`);
  return { swr: useSWR<BillingDetails, ErrorRes>(url, fetcher), url };
};

export const updateBillingDetailsItem = async (
  userId: string, detailsId: string, body = {},
) => sendToServer<BillingDetails>(
  'PUT',
  `/users/${userId}/billingDetails/${detailsId}`,
  {},
  body,
);

export const createBillingDetails = (userId: string, body = {}) => sendToServer<BillingDetails>(
  'POST',
  `/users/${userId}/billingDetails`,
  {},
  body,
);
