import useSWR, { cache, mutate } from 'swr';
import _ from 'lodash';
import { createFetcher, downloadFile, sendToServer } from './Requests';
import { ErrorRes, Invoice } from '../types';
import { InvoiceStatus } from '../types/Invoices';

export const fetchInvoices = (query = {}) => {
  const { url, fetcher } = createFetcher('/invoices', query);
  return { swr: useSWR<Invoice[], ErrorRes>(url, fetcher), url };
};

export const fetchInvoiceDetails = (invoiceId?: string) => {
  const { url, fetcher } = createFetcher(`/invoices/${invoiceId || ''}`);
  return { swr: useSWR<Invoice, ErrorRes>(invoiceId ? url : null, fetcher), url };
};

const patchInvoice = async (invoiceId: string, body = {}) => sendToServer<Invoice>(
  'PATCH',
  `/invoices/${invoiceId}`,
  {},
  body,
);

export const updateInvoiceStatus = async (
  invoiceId: string, status: InvoiceStatus,
) => patchInvoice(invoiceId, { status });

export const putInvoice = async (invoiceId: string, body = {}) => sendToServer<Invoice>(
  'PUT',
  `/invoices/${invoiceId}`,
  {},
  body,
);

export const fetchInvoicePdf = (invoiceId: string) => (
  downloadFile(`/invoices/${invoiceId}`, { $format: 'pdf' }, `Invoice-${invoiceId}`)
);

export const invalidateCache = async (keyToInvalid = '/invoices') => {
  const keys = Array.from(cache.keys());
  const matchingKeys = keys.filter((key) => key.includes(keyToInvalid));
  await Promise.all(matchingKeys.map((key) => mutate(key, undefined, true)));
};

const addInvoiceItem = async (invoiceId: string, body = {}) => sendToServer<Invoice>(
  'POST',
  `/invoices/${invoiceId}/items`,
  {},
  body,
);

const updateInvoiceItem = async (
  invoiceId: string, itemId: string, body = {},
) => sendToServer<Invoice>(
  'PUT',
  `/invoices/${invoiceId}/items/${itemId}`,
  {},
  body,
);

const deleteInvoiceItem = async (
  invoiceId: string, itemId: string,
) => sendToServer<Invoice>(
  'DELETE',
  `/invoices/${invoiceId}/items/${itemId}`,
);

export const updateInvoiceCorrectionItem = async (
  invoice: Invoice, desc: string, amount: number, itemId?: string,
) => {
  const { items } = invoice;
  if (!items || items.length === 0) return null;
  const otherItems = _.filter(invoice.items, (item) => _.get(item, 'data.refItemId') !== items[0].id);

  const body = {
    type: 'correction',
    quantity: 1,
    unitNet: amount * 100, // convert to cent
    data: { refItemId: otherItems[0].id, desc },
  };
  if (itemId && desc && amount !== 0) {
    return updateInvoiceItem(invoice.id, itemId, body);
  }

  if (itemId && amount === 0) {
    return deleteInvoiceItem(invoice.id, itemId);
  }

  if (!itemId && amount === 0) return null;
  return addInvoiceItem(invoice.id, body);
};
