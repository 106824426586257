import React, { useContext, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import { mutate } from 'swr';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { RootStoreContext } from '../stores/RootStore';
import Style from '../style';
import { fetchUser, updateUser } from '../api/Users';
import Button from './Button';
import { SUCCESS } from '../util/helpers';
import ApiNotifBottom from './ApiNotifBottom';
import I18n from '../i18n';
import CTextInput from './customfields/CTextInput';
import { ProfileFormSchema } from '../util/validation';
import CDropdown from './customfields/CDropdown';

type FormData = yup.InferType<typeof ProfileFormSchema>;

interface EPDProps {
  closeModal: () => void;
}

const EditPersonalData = observer(({ closeModal }: EPDProps) => {
  const store = useContext(RootStoreContext);
  const myId = store.auth.userId;
  if (!myId) return null;
  const { data: myDetails } = fetchUser(myId).swr;
  const fetchUserUrl = fetchUser(myId).url;
  if (myDetails && 'httpStatus' in myDetails) store.uiState.checkError(myDetails);
  if (!myDetails) return null;

  const genders = [
    ['m', I18n.t('ui.personalData.gender.m')],
    ['f', I18n.t('ui.personalData.gender.f')],
    ['o', I18n.t('ui.personalData.gender.o')],
  ];
  const [loading, setLoading] = useState(false);
  const [apiRes, setApiRes] = useState('');

  const methods = useForm<FormData>({
    defaultValues: {
      firstName: myDetails.firstName || '',
      lastName: myDetails.lastName,
      gender: myDetails.gender || 'm',
      email: myDetails.email,
    },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    resolver: yupResolver(ProfileFormSchema),
  });

  const onSubmit = methods.handleSubmit((data: FormData) => save(data));

  const save = async (data: FormData) => {
    setApiRes('');
    const { firstName, lastName, gender, email } = data;
    const body = {
      ...(firstName !== '' && { firstName: firstName.trim() }),
      ...(lastName !== '' ? { lastName: lastName?.trim() } : { lastName: null }),
      ...(gender !== '' && { gender }),
      ...(myDetails.email !== email.trim() && { email: email.trim() }),
    };
    setLoading(true);
    try {
      const res = await updateUser(myId, body);
      if (res.id) await mutate(fetchUserUrl, res);
      setApiRes(SUCCESS);
      setTimeout(() => { closeModal(); }, 2000);
    } catch (err) {
      if (err instanceof Error) {
        setApiRes(err.message.toString());
        setTimeout(() => { setApiRes(''); }, 4300);
      }
    } finally {
      setLoading(false);
    }
  };

  const editInfo = () => (
    <View style={styles.inputView}>
      <CTextInput
        name="firstName"
        caption={I18n.t('ui.personalData.firstName')}
      />
      <CTextInput
        name="lastName"
        caption={I18n.t('ui.personalData.lastName')}
      />
      <CDropdown
        name="gender"
        caption={I18n.t('ui.personalData.gender.title')}
        options={genders}
      />
      <CTextInput
        name="email"
        caption={I18n.t('ui.login.email')}
        placeholder="alice@example.com"
      />
    </View>
  );

  return (
    <View style={styles.container}>
      <Text style={styles.header}>{I18n.t('ui.personalData.edit')}</Text>
      <FormProvider {...methods}>
        <View style={styles.form}>
          {editInfo()}
        </View>
        <View style={styles.buttonPad}>
          <Button
            btnStyleHovered={styles.btnSaveHovered}
            onPress={onSubmit}
            title={I18n.t('ui.buttons.save')}
            testId="PersonalData.EditButton"
            disable={!methods.formState.isDirty}
            icon="PaperPlaneRight"
            loading={loading}
          />
        </View>
      </FormProvider>
      {!!apiRes && <View style={styles.disableLayer} />}
      {!!apiRes && <ApiNotifBottom apiRes={apiRes} successText={I18n.t('other.updatedSuccessfully')} />}
    </View>
  );
});

export default EditPersonalData;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignItems: 'flex-start',
    flex: 1,
    paddingHorizontal: 30,
    backgroundColor: Style.Color.White,
  },
  form: {
    width: '100%',
    alignItems: 'flex-start',
  },
  header: {
    ...Style.Text.Heading2,
    color: Style.Color.Black,
    textAlign: 'center',
    width: '100%',
    marginBottom: 25,
    zIndex: 10,
  },
  buttonPad: {
    width: '100%',
    height: 40,
    alignSelf: 'center',
    marginBottom: 24,
    marginTop: 24,
  },
  btnSaveHovered: {
    marginLeft: 4,
    backgroundColor: Style.Color.Primary,
  },
  inputView: {
    width: '100%',
  },
  disableLayer: {
    backgroundColor: Style.Color.White,
    height: '100%',
    width: '100%',
    position: 'absolute',
    opacity: 0.5,
    borderRadius: 4,
  },
});
