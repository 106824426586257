import React, { useRef } from 'react';
import {
  StyleSheet, TextInput,
  ViewStyle, TextInputProps,
} from 'react-native';
import { FieldError, useFormContext } from 'react-hook-form';

import Style from '../../style';
import FormInput from './FormInput';

type Props = {
  name: string,
  caption?: string,
  placeholder?: string,
  masked?: boolean,
  disabled?: boolean,
  boxStyle?: ViewStyle,
  errIconAlign?: 'left' | 'right',
  testId?: string,
  submit?: () => void,
} & TextInputProps;

const CTextInput = ({
  name, caption, placeholder, masked, disabled,
  boxStyle, errIconAlign = 'right', testId, submit,
}: Props) => {
  const inputRef = useRef<TextInput | null>(null);
  const { formState: { errors } } = useFormContext();
  const error = errors[name] as FieldError | undefined;

  return (
    <FormInput
      name={name}
      disabled={disabled}
      caption={caption}
      errIconAlign={errIconAlign}
      textInputRef={inputRef}
      render={({ value, onChange, onBlur }) => (
        <TextInput
          ref={inputRef}
          style={[styles.input, boxStyle, error && styles.inputError]}
          placeholder={placeholder}
          placeholderTextColor={Style.Color.Gray300}
          onBlur={onBlur}
          onChangeText={onChange}
          value={value as string || ''}
          secureTextEntry={masked}
          editable={!disabled}
          testID={testId}
          onKeyPress={(event) => {
            if (event.nativeEvent.key === 'Enter' && submit) {
              submit();
            }
          }}
        />
      )}
    />
  );
};

export default CTextInput;

const styles = StyleSheet.create({
  input: {
    width: '100%',
    height: 40,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: Style.Color.Gray300,
    outlineColor: Style.Color.Gray300,
    color: Style.Color.Gray600,
    ...Style.Text.Normal,
    backgroundColor: Style.Color.White,
    paddingHorizontal: 10,
  },
  inputError: {
    color: Style.Color.Tertiary,
    borderColor: Style.Color.Tertiary,
    outlineColor: Style.Color.Tertiary,
    marginBottom: 0,
  },
});
