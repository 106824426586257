import React, { useContext, useRef, useEffect, useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { observer } from 'mobx-react-lite';

import { RootStoreContext } from '../stores/RootStore';
import ScreenLayout from './ScreenLayout';
import AccessErrorScreen from './AccessErrorScreen';
import { invoiceEditAccess, screenAccess } from '../navigation/AccessRules';
import InvoicesSummary from '../components/invoices/InvoicesSummary';
import { InvoiceStatus } from '../types/Invoices';
import { RefObject } from '../types';
import { getUrlParamValue } from '../util/helpers';

const InvoicesScreen = observer(() => {
  const store = useContext(RootStoreContext);
  if (!store.auth.checkAccess(screenAccess.Invoices)) return <AccessErrorScreen />;
  const hasEditAccess = store.auth.checkAccess(invoiceEditAccess);

  const refPending = useRef<RefObject>(null);
  const refDisputed = useRef<RefObject>(null);
  const refAccepted = useRef<RefObject>(null);
  const refPaid = useRef<RefObject>(null);

  const [highlightedInvoiceId, setHighlightedInvoiceId] = useState<string | undefined>(undefined);

  useEffect(() => {
    // Auto select invoice row if invoiceId provided in url
    const invoiceId = getUrlParamValue('invoiceId');
    if (invoiceId) {
      setTimeout(() => {
        setHighlightedInvoiceId(invoiceId);
      }, 300);
    }
  }, []);

  const resetComponent = (name: InvoiceStatus | 'all') => {
    if (['pending', 'all'].includes(name) && refPending.current) {
      refPending.current.resetAllState();
    }
    if (['disputed', 'all'].includes(name) && refDisputed.current) {
      refDisputed.current.resetAllState();
    }
    if (['accepted', 'all'].includes(name) && refAccepted.current) {
      refAccepted.current.resetAllState();
    }
    if (['paid', 'all'].includes(name) && refPaid.current) {
      refPaid.current.resetAllState();
    }
  };

  const resetOtherComponent = (componentName: InvoiceStatus) => {
    if (componentName !== 'pending') resetComponent('pending');
    if (componentName !== 'disputed') resetComponent('disputed');
    if (componentName !== 'accepted') resetComponent('accepted');
    if (componentName !== 'paid') resetComponent('paid');
  };

  const pressResetAll = () => {
    resetComponent('all');
  };

  const sections = {
    pending: <InvoicesSummary key="pending" status="pending" resetOtherComponent={resetOtherComponent} ref={refPending} highlightedInvoiceId={highlightedInvoiceId} />,
    disputed: <InvoicesSummary key="disputed" status="disputed" resetOtherComponent={resetOtherComponent} ref={refDisputed} highlightedInvoiceId={highlightedInvoiceId} />,
    accepted: <InvoicesSummary key="accepted" status="accepted" resetOtherComponent={resetOtherComponent} ref={refAccepted} highlightedInvoiceId={highlightedInvoiceId} />,
    paid: <InvoicesSummary key="paid" status="paid" resetOtherComponent={resetOtherComponent} ref={refPaid} highlightedInvoiceId={highlightedInvoiceId} />,
  };

  const orderedSections = hasEditAccess
    ? [sections.disputed, sections.accepted, sections.pending, sections.paid]
    : [sections.pending, sections.disputed, sections.accepted, sections.paid];

  return (
    <ScreenLayout>
      <View style={styles.container}>
        <TouchableOpacity onPress={pressResetAll} style={styles.outside} />
        {orderedSections}
      </View>
    </ScreenLayout>
  );
});

export default InvoicesScreen;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignItems: 'center',
    flex: 1,
    paddingHorizontal: 20,
  },
  outside: {
    top: -100,
    position: 'absolute',
    width: '200%',
    height: '102%',
    cursor: 'auto',
  },
});
