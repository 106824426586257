import _ from 'lodash';

export const roles = {
  user: 'user',
  coach: 'coach',
  supervisor: 'supervisor',
  admin: 'admin',
  support: 'support',
  pm: 'pm',
  ops: 'ops',
  qa: 'qa',
  client: 'client',
  acct: 'acct',
};

export const allRoles = Object.values(roles);
export const screenAccess: { [key: string]: string[] } = {
  // Home: [roles.pm],
  Calls: [roles.coach, roles.supervisor, roles.support, roles.qa],
  CallDetails: [roles.coach, roles.supervisor, roles.support, roles.qa, roles.ops],
  Availabilities: [roles.coach, roles.supervisor, roles.support],
  Projects: [roles.pm, roles.support, roles.ops, roles.qa],
  Coachings: [roles.coach, roles.support, roles.qa],
  Profile: allRoles,
  Invoices: [roles.support, roles.ops, roles.coach, roles.acct],
};

export const sectionAccess: { [key: string]: string[] } = {
  WeeklyAvailabilityPicker: [roles.supervisor],
  BillingInfo: [roles.coach, roles.supervisor, roles.ops, roles.support],
};

export const sidebarSectionAccess: { [key: string]: string[] } = {
  Coaching: _.union(
    screenAccess.Calls, screenAccess.Availabilities, screenAccess.Coachings,
  ),
  Resources: _.union(screenAccess.Projects, screenAccess.Invoices),
  Settings: screenAccess.Profile,
  Invoices: screenAccess.Invoices,
  // Dashboards: screenAccess.Home,
};

export const invoiceEditAccess = [roles.admin, roles.ops, roles.acct];

export const getInitialRouteName = (role: string) => {
  switch (role) {
    case roles.coach:
    case roles.supervisor:
    case roles.support:
      return 'Calls';
    case roles.ops:
    case roles.pm:
      return 'Projects';
    case roles.acct:
      return 'Invoices';
    default:
      return 'Profile';
  }
};
