import React, { useContext, useRef, useState } from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import _ from 'lodash';
import { trigger } from 'swr';

import { RootStoreContext } from '../../stores/RootStore';
import Style from '../../style';
import { getBrowserInfo } from '../HeaderBar';
import Button from '../Button';
import I18n, { formatDate, formatAmount } from '../../i18n';
import { fetchUser } from '../../api/Users';
import { Coaching } from '../../types';
import { fetchBillingDetailsList } from '../../api/BillingDetails';
import { capFirstLetter, formatIban, SUCCESS, validateBillingInfo } from '../../util/helpers';
import { fetchInvoices, updateInvoiceStatus, invalidateCache, updateInvoiceCorrectionItem } from '../../api/invoices';
import { Correction, Invoice, InvoiceItem, InvoiceStatus } from '../../types/Invoices';
import InvoiceActionButtons from './InvoiceActionButtons';
import ApiNotifBottom from '../ApiNotifBottom';
import { invoiceEditAccess } from '../../navigation/AccessRules';
import TableRow, { TableRowMethods, tableWidth } from './TableRow';
import LoadingIndicator from '../LoadingIndicator';
import ModalView, { getDrawerWidth } from '../ModalView';
import EditBillingInfo from '../EditBillingInfo';

interface InvoiceProps {
  coaching: Coaching,
  onStatusChange: (invoice: Invoice) => void;
  closeModal: () => void;
}

const InvoiceModal = observer(({ coaching, onStatusChange, closeModal }: InvoiceProps) => {
  const store = useContext(RootStoreContext);
  const myId = store.auth.userId;
  if (!myId) return null;
  const { browser, compactView } = getBrowserInfo();
  const [apiRes, setApiRes] = useState('');
  const [loading, setLoading] = useState(false);
  const [activeModal, setActiveModal] = useState('');
  const tableRowRef = useRef<TableRowMethods>(null);
  const [submittedStatus, setSubmittedStatus] = useState('');
  const { coachId } = coaching;
  const isCoach = myId === coachId;
  const hasEditAccess = store.auth.checkAccess(invoiceEditAccess);
  const { data: payee, error: errorPayee } = fetchUser(coachId).swr;
  const { data: billings, error: errorBilling } = fetchBillingDetailsList(coachId).swr;
  const { data: invoices } = fetchInvoices({ coachingId: coaching.id, $view: 'full' }).swr;
  const invoicesFetchUrl = fetchInvoices({ coachingId: coaching.id, $view: 'full' }).url;

  if (errorPayee || errorBilling) {
    return null;
  }

  const invoice = invoices?.[0];
  const invoiceStatus = invoice?.status;
  const isStatusPending = invoiceStatus === 'pending';
  const allowCorrection = !isCoach && hasEditAccess && invoice?.status === 'disputed';
  const billingDetails = Array.isArray(billings) ? billings[0] : undefined;
  const identifications = {
    vatId: billingDetails?.vatId,
    iban: billingDetails?.bankIban,
    taxId: billingDetails?.taxId,
  };

  const submitCorrection = async (desc: string, amount: number, itemId?: string) => {
    if (!invoice) return false;
    setLoading(true);
    try {
      const res = await updateInvoiceCorrectionItem(invoice, desc, amount, itemId);
      if (res?.id || res?.status) {
        await invalidateCache();
        await trigger(invoicesFetchUrl);
      }
      return !!res;
    } catch (err) {
      if (err instanceof Error) {
        setApiRes(err.message.toString());
        setTimeout(() => { setApiRes(''); }, 4300);
      }
    } finally {
      setLoading(false);
    }
    return false;
  };

  const pressActionButton = async (status: InvoiceStatus) => {
    if (!invoice || !billingDetails) return;
    if (invoice.status === 'disputed' && status === 'pending') {
      const submitCorr = await tableRowRef.current?.pressSubmit();
      if (!submitCorr) return;
    }

    if (!validateBillingInfo(billingDetails)) {
      setActiveModal('BillingInfoAlert');
      return;
    }

    setApiRes('');
    setLoading(true);
    setSubmittedStatus('');
    try {
      const res = await updateInvoiceStatus(invoice.id, status);
      if (res) {
        await invalidateCache();
        onStatusChange(res);
      }
      setApiRes(SUCCESS);
      setSubmittedStatus(status);
      setTimeout(() => { closeModal(); }, 2000);
    } catch (err) {
      if (err instanceof Error) {
        setApiRes(capFirstLetter(err.message.toString()));
        setTimeout(() => { setApiRes(''); }, 4300);
      }
    } finally {
      setLoading(false);
    }
  };

  const closeEditModal = () => { setActiveModal(''); };

  const renderBillingInfoAlert = () => {
    if (activeModal !== 'BillingInfoAlert') return null;
    return (
      <ModalView
        refName="BillingInfoAlert"
        isVisible
        closeModal={closeEditModal}
        drawer="right"
      >
        <View style={styles.billingAlertView}>
          <Text style={styles.billingAlertTitle}>{I18n.t('error.billings.alert.title')}</Text>
          <Text style={styles.billingAlertDes}>{I18n.t('error.billings.alert.des')}</Text>
          {renderPayee()}
          {renderIdsSection()}
          {identifications.iban && (
            <View style={styles.row}>
              <Text style={styles.idsTitle}>{I18n.t('ui.invoices.iban')}</Text>
              <Text style={styles.infoText}>{formatIban(identifications.iban)}</Text>
            </View>
          )}
        </View>
      </ModalView>
    );
  };

  const renderEditModal = () => {
    if (activeModal !== 'BillingInfo') return null;

    return (
      <ModalView
        refName="EditBillingInfo"
        isVisible
        closeModal={closeEditModal}
        drawer="right"
      >
        {activeModal === 'BillingInfo'
          && <EditBillingInfo closeModal={closeEditModal} />}
      </ModalView>
    );
  };

  const renderPayee = () => (
    <View style={styles.infoSection}>
      <View style={styles.infoCol}>
        <Text style={styles.infoText}>{`${billingDetails?.firstName || ''} ${billingDetails?.lastName || ''}`}</Text>
        <Text style={styles.infoText}>
          {`${billingDetails?.street || ''}\n${billingDetails?.zipCode || ''} ${billingDetails?.city || ''}`}
        </Text>
      </View>
      {isStatusPending && isCoach && (
        <View style={styles.infoCol}>
          <Button
            btnStyle={styles.changeButton}
            btnStyleHovered={styles.changeButtonH}
            onPress={() => setActiveModal('BillingInfo')}
            title={I18n.t('ui.buttons.changeBillingInfo')}
            titleStyle={styles.changeButtonText}
            titleStyleHovered={styles.changeButtonTextH}
          />
        </View>
      )}
    </View>
  );

  const renderIdsSection = () => {
    if (!payee) return null;
    return (
      <View style={styles.infoSection}>
        <View style={styles.infoCol}>
          <View style={styles.row}>
            <Text style={styles.idsTitle}>{I18n.t('ui.invoices.date')}</Text>
            <Text style={styles.infoText}>{formatDate('date.dayMonthYearShort', invoice?.created)}</Text>
          </View>

          {identifications.vatId && (
            <View style={styles.row}>
              <Text style={styles.idsTitle}>{I18n.t('ui.invoices.vatId')}</Text>
              <Text style={styles.infoText}>{identifications.vatId}</Text>
            </View>
          )}

          {identifications.taxId && (
            <View style={styles.row}>
              <Text style={styles.idsTitle}>{I18n.t('ui.invoices.taxId')}</Text>
              <Text style={styles.infoText}>{identifications.taxId}</Text>
            </View>
          )}
        </View>
      </View>
    );
  };

  const addItem = (item: InvoiceItem) => {
    if (_.includes(['coaching', 'nsc'], item.type)) {
      return (
        <TableRow
          key={item.id}
          col0={item.strings.desc}
          col1={formatAmount(item.unitNet)}
          col2={item.quantity.toString()}
          col3={formatAmount(item.amountNet)}
        />
      );
    }

    if (item.type === 'correction') {
      const { desc } = item.data as Correction;
      return (
        <TableRow
          key={item.id}
          itemId={item.id}
          col0={allowCorrection ? desc : item.strings.desc}
          col3={allowCorrection ? `${item.amountNet / 100}` : formatAmount(item.amountNet)}
          rowStyle={{ marginBottom: 40 }}
          type={allowCorrection ? 'inputBox' : 'text'}
          submitCorrection={submitCorrection}
          ref={tableRowRef}
        />
      );
    }

    return (
      <Text style={[styles.bodyDes, { color: Style.Color.Tertiary }]}>
        {`${item.type as string} ${I18n.t('ui.invoices.typeNotSupported')}`}
      </Text>
    );
  };

  const renderTransaction = () => {
    if (!payee || !invoice) return null;

    const { sumNet, sumGross, vatSums, strings } = invoice;
    // Considering same vat info for all items,
    // if it's different (more than 1 value in array),
    // we need to redesign UI, showing vat details in every item row)
    const vatInfo = vatSums?.[0];
    const vatPercentage = vatInfo?.vatPercent;
    const vatSum = vatInfo?.vatSum;
    const hasCorrection = _.some(invoice.items, { type: 'correction' });

    return (
      <View style={styles.transcation}>
        <Text style={styles.bodyTitle}>{I18n.t('ui.invoices.creditNote')}</Text>
        <Text style={styles.bodyDes}>{strings.intro}</Text>
        <View style={styles.table}>
          <TableRow
            key="header"
            col0={I18n.t('ui.invoices.letter.tableTitle.item')}
            col1={I18n.t('ui.invoices.letter.tableTitle.price')}
            col2={I18n.t('ui.invoices.letter.tableTitle.quantity')}
            col3={I18n.t('ui.invoices.letter.tableTitle.total')}
            title
          />
          <View style={styles.tableDivider} />
          {invoice?.items?.map((item) => addItem(item))}
          {!hasCorrection && allowCorrection && (
            <TableRow
              key="correction"
              col0=""
              col3=""
              type="inputBox"
              rowStyle={{ marginBottom: 40 }}
              submitCorrection={submitCorrection}
              ref={tableRowRef}
            />
          )}
          <View style={styles.vSpace} />
          <TableRow
            key="net"
            col0={I18n.t('ui.invoices.letter.tableValue.totalNet')}
            col3={formatAmount(sumNet)}
            rowStyle={styles.bold}
          />
          {vatPercentage && vatSum && (
            <TableRow
              key="vat"
              col0={I18n.t('ui.invoices.letter.tableValue.vat', { val: vatPercentage })}
              col3={formatAmount(vatSum)}
              rowStyle={styles.vat}
            />
          )}
          <View style={styles.tableDivider} />
          <TableRow
            key="total"
            col0={I18n.t('ui.invoices.letter.tableValue.total')}
            col3={formatAmount(sumGross || sumNet)}
            rowStyle={styles.bold}
          />
        </View>
        <View style={styles.vSpace} />
        <Text style={styles.bodyDes}>{strings.outro}</Text>
      </View>
    );
  };

  const renderInvoice = () => (
    <ScrollView
      horizontal
      style={{
        width: getDrawerWidth(browser.width, compactView) - 70,
        alignSelf: compactView ? 'flex-start' : 'center',
      }}
    >
      <View style={styles.invoice}>
        <Text style={styles.draftText}>{I18n.t('ui.invoices.draft')}</Text>
        {renderPayee()}
        {renderIdsSection()}
        {renderTransaction()}
      </View>
    </ScrollView>
  );

  if (!invoice) {
    return (
      <View style={styles.container}>
        <View style={styles.status}>
          <Text style={styles.invoiceStatus}>{I18n.t('ui.invoices.notReady')}</Text>
        </View>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.status}>
        <Text style={styles.invoiceStatus}>
          {I18n.t(`ui.invoices.status.${isCoach ? 'payee' : 'payer'}.${invoice?.status || 'pending'}`,
            { firstName: payee?.firstName })}
        </Text>
      </View>
      <ScrollView showsHorizontalScrollIndicator={false}>
        {renderInvoice()}
        <InvoiceActionButtons
          status={invoice.status}
          isCoach={isCoach}
          onPress={pressActionButton}
        />
      </ScrollView>
      {!!loading && (
        <View style={styles.disableLayer}>
          <LoadingIndicator />
        </View>
      )}
      {!!apiRes && (
        <ApiNotifBottom
          apiRes={apiRes}
          successText={I18n.t(`ui.invoices.successNotif.${submittedStatus}`)}
        />
      )}
      {renderEditModal()}
      {renderBillingInfoAlert()}
    </View>
  );
});

export default InvoiceModal;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    alignItems: 'flex-start',
    backgroundColor: Style.Color.White,
    paddingTop: 30,
    flex: 1,
    paddingLeft: 50,
  },
  invoice: {
    backgroundColor: Style.Color.Gray100,
    alignSelf: 'center',
    paddingHorizontal: 30,
    paddingTop: 30,
    borderColor: Style.Color.Gray600,
    borderWidth: 1,
    marginBottom: 10,
  },
  infoSection: {
    marginBottom: 30,
    flexDirection: 'row',
  },
  transcation: {
    marginBottom: 30,
  },
  infoCol: {
    flexDirection: 'column',
    width: '50%',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  infoText: {
    ...Style.Text.Normal,
    color: Style.Color.Black,
  },
  status: {
    marginBottom: 30,
    width: '80%',
    alignSelf: 'center',
  },
  changeButton: {
    backgroundColor: Style.Color.White,
    borderWidth: 1,
    borderColor: Style.Color.Gray800,
  },
  changeButtonText: {
    ...Style.Text.Normal,
    color: Style.Color.Gray800,
  },
  changeButtonH: {
    backgroundColor: Style.Color.Primary,
    borderColor: Style.Color.Primary,
  },
  changeButtonTextH: {
    color: Style.Color.White,
  },
  invoiceStatus: {
    ...Style.Text.NormalBold,
    color: Style.Color.Black,
    width: '100%',
    textAlign: 'center',
  },
  row: {
    flexDirection: 'row',
    paddingBottom: 5,
  },
  idsTitle: {
    ...Style.Text.NormalItalic,
    color: Style.Color.Black,
  },
  table: {
    flexDirection: 'column',
    width: '100%',
  },
  tableDivider: {
    width: tableWidth,
    borderColor: Style.Color.Gray400,
    borderWidth: 1,
  },
  bodyTitle: {
    ...Style.Text.Heading1,
    color: Style.Color.Black,
    marginBottom: 20,
  },
  bodyDes: {
    ...Style.Text.Normal,
    color: Style.Color.Black,
    marginBottom: 20,
    maxWidth: tableWidth,
  },
  bold: {
    ...Style.Text.NormalBold,
  },
  vSpace: {
    height: 20,
  },
  vat: {
    ...Style.Text.NormalItalic,
    color: Style.Color.Gray400,
  },
  draftText: {
    position: 'absolute',
    top: '40%',
    ...Style.Text.Heading2,
    transform: [
      { rotate: '-45deg' },
    ],
    fontSize: 150,
    color: 'rgba(0, 0, 0, 0.1)',
    fontWeight: 'bold',
    textAlign: 'center',
    alignSelf: 'center',
    zIndex: -1,
  },
  disableLayer: {
    backgroundColor: Style.Color.White,
    height: '100%',
    width: '100%',
    position: 'absolute',
    opacity: 0.5,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  billingAlertView: {
    marginLeft: 50,
    width: '100%',
  },
  billingAlertTitle: {
    ...Style.Text.Heading3,
    textAlign: 'center',
  },
  billingAlertDes: {
    marginTop: 20,
    ...Style.Text.NormalBold,
    color: Style.Color.Black,
    width: '100%',
    textAlign: 'center',
    marginBottom: 40,
  },
});
