import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import Style from '../../style';
import { Coaching } from '../../types';
import CoachingRow from './CoachingRow';
import I18n from '../../i18n';

interface CMProps {
  coaching: Coaching,
}

const CoachingModal = ({ coaching }: CMProps) => (
  <View style={styles.container}>
    <Text style={styles.header}>{I18n.t('ui.coachings.coachingDetails')}</Text>
    <CoachingRow
      key={coaching.id}
      coaching={coaching}
      selectedCoaching={undefined}
      selectedCoachingCell=""
      isLastRow
      onActionButtonPress={() => { }}
      toggleActiveCell={() => { }}
      setSelectedCoachingFromChild={() => { }}
      modalView
    />
  </View>
);

export default CoachingModal;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    alignItems: 'flex-start',
    backgroundColor: Style.Color.White,
    paddingBottom: 30,
  },
  header: {
    ...Style.Text.Heading2,
    color: Style.Color.Black,
    textAlign: 'center',
    width: '100%',
    marginBottom: 25,
    zIndex: 10,
  },
});
