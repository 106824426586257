import React, { useEffect, useRef } from 'react';
import {
  StyleSheet, Animated,
} from 'react-native';
import { FieldError } from 'react-hook-form';

import Style from '../../style';

type Props = {
  error: FieldError,
  focus?: () => void,
};

const FieldErrorMsg = ({ error, focus }: Props) => {
  const shakeAnimation = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    shake();
    focus?.();
  }, [error]);

  const shake = () => {
    Animated.sequence([
      Animated.timing(shakeAnimation, { toValue: 10, duration: 100, useNativeDriver: true }),
      Animated.timing(shakeAnimation, { toValue: -10, duration: 100, useNativeDriver: true }),
      Animated.timing(shakeAnimation, { toValue: 10, duration: 100, useNativeDriver: true }),
      Animated.timing(shakeAnimation, { toValue: 0, duration: 100, useNativeDriver: true }),
    ]).start();
  };

  if (!error) return null;

  return (
    <Animated.Text
      style={[
        styles.errMsg,
        { transform: [{ translateX: shakeAnimation }] },
      ]}
    >
      {error.message}
    </Animated.Text>
  );
};

export default FieldErrorMsg;

const styles = StyleSheet.create({
  errMsg: {
    height: 18,
    ...Style.Text.Tiny,
    color: Style.Color.Tertiary,
    alignSelf: 'flex-end',
    paddingTop: 3,
    paddingBottom: 2,
  },
});
