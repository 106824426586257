import * as Localization from 'expo-localization';
import I18n from 'i18n-js';
import moment from 'moment-timezone';
import _ from 'lodash';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import deLocale from 'i18n-iso-countries/langs/de.json';

import translations from './translations';

I18n.fallbacks = true;
I18n.translations = translations;
I18n.defaultLocale = 'en-US';
I18n.locale = Localization.locale;
countries.registerLocale(enLocale);
countries.registerLocale(deLocale);

export const getCurrentLang = () => Localization.locale;
export const getCurrentTimezone = () => Localization.timezone;
moment.locale(getCurrentLang());

// Note: Since i18n-js unfortunately doesn't come with locale templates for dates, but
// momentJS does, we extend its capabilities with some moment goodness.
// date: string | Date not needed as moment input cover all of them
// see https://momentjs.com/docs/#/displaying/format/ to create new one
type DateFormat =
  'date.appointmentDate' | 'date.appointmentDateTime' | 'date.appointmentDayDateTime' | 'date.dateRel' | 'date.dateShort'
  | 'date.dateTimeRel' | 'date.dateTimeRelShort' | 'date.dayMonthShort'
  | 'date.dayShort' | 'date.fullDate' | 'date.fullDateMonthName'
  | 'date.fullDateTime' | 'date.monthNameYear' | 'date.monthShort'
  | 'date.shortDateTime' | 'date.time' | 'date.timeExpl' | 'date.timeSpan' | 'date.timeColon'
  | 'date.timeSpanShort' | 'date.formatedTime' | 'date.monthYearShort' | 'date.dayMonthYearShort';

export const formatDate = (format: DateFormat, date: moment.MomentInput) => (
  moment(date).locale(getCurrentLang()).format(I18n.t(format)));

// Formats a date relative to the current time, distinguishing today, yesterday and all other dates.
export const formatDateRel = (date: moment.MomentInput) => (
  moment(date).locale(getCurrentLang()).calendar(I18n.t('date.dateRel')));

// Formats a timestamp relative to the current time, distinguishing today, yesterday, the past week
// and a all other dates.

export const formatDateTimeRel = (date: moment.MomentInput, short = false) => (
  moment(date).locale(getCurrentLang())
    .calendar(I18n.t(short ? 'date.dateTimeRelShort' : 'date.dateTimeRel')));

export const formatAmount = (number: number, { currency = 'EUR' } = {}) => new Intl.NumberFormat(
  getCurrentLang(), { style: 'currency', currency },
).format(number / 100); // to make it euro.cent

export const getCountryNames = () => {
  const supportedLangs = ['en', 'de'];
  let lang = _.get(getCurrentLang().split('-'), 0);
  if (!supportedLangs.includes(lang)) {
    lang = 'en';
  }
  return countries.getNames(lang, { select: 'official' });
};

export const currencyPos = ({ currency = 'EUR' } = {}) => {
  const amountStr = new Intl.NumberFormat(
    getCurrentLang(), { style: 'currency', currency },
  ).format(123456 / 100); // a sample amount with format euro.cent

  if (/^\d/.test(amountStr)) {
    return 'right';
  }
  return 'left';
};

export default I18n;
