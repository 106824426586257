import React, { useContext } from 'react';
import { StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native';
import { Hoverable } from 'react-native-web-hooks';
import { observer } from 'mobx-react-lite';

import Style from '../../style';
import I18n, { formatAmount, formatDate } from '../../i18n';
import { Coaching, Invoice } from '../../types';
import { getBrowserInfo } from '../HeaderBar';
import ButtonSmall from '../ButtonSmall';
import UserInfo from '../UserInfo';
import { fetchUser } from '../../api/Users';
import { fetchCoachingDetails } from '../../api/Coachings';
import { fetchProgramByKey } from '../../api/Programs';
import { fetchOrganizationDetails } from '../../api/Organizations';
import { InvoiceStatus } from '../../types/Invoices';
import { RootStoreContext } from '../../stores/RootStore';
import { invoiceEditAccess } from '../../navigation/AccessRules';

export type ButtonOptions = 'View Invoice' | 'View Coaching';
export type SelectedOptions = {
  invoice: Invoice,
  coaching: Coaching,
  activeButton?: ButtonOptions,
};

const getButtonTitle = (status: InvoiceStatus, isCoach: boolean, hasEditAccess: boolean) => {
  if (isCoach) {
    if (status === 'pending') return 'reviewInvoice';
    if (status === 'disputed') return 'viewInvoice';
  }

  if (hasEditAccess) {
    if (status === 'pending') return 'viewInvoice';
    if (status === 'disputed') return 'reviewInvoice';
  }

  if (['accepted', 'paid'].includes(status)) return 'downloadInvoice';
  return 'viewInvoice';
};

interface IRProps {
  invoice: Invoice;
  selectedInvoice?: SelectedOptions; // For modal
  // For compact view, as we show button only when active/selected (not in hover)
  selectedInvoiceCell: string;
  isLastRow: boolean;
  toggleActiveCell: (invoiceId: string, isActiveCellCompact: boolean) => void;
  onActionButtonPress: (invoice: Invoice, coaching: Coaching, activeButton: ButtonOptions) => void;
  resetOtherComponent: (name: InvoiceStatus) => void;
}

const InvoiceRow = observer(({
  invoice, selectedInvoice, selectedInvoiceCell, isLastRow,
  toggleActiveCell, onActionButtonPress, resetOtherComponent,
}: IRProps) => {
  const store = useContext(RootStoreContext);
  const myId = store.auth.userId;
  const myRoles = store.auth.roles;
  if (!myId || !myRoles) return null;
  const { compactView } = getBrowserInfo();
  const isActive = (selectedInvoice?.invoice.id === invoice.id);
  const isActiveCellCompact = (selectedInvoiceCell === invoice.id);
  const { data: coaching } = fetchCoachingDetails(invoice.coachingId).swr;
  const { data: user } = fetchUser(coaching?.clientId).swr;
  const { data: coach } = fetchUser(coaching?.coachId).swr;
  const { data: program } = fetchProgramByKey(coaching?.programKey).swr;
  const { data: org } = fetchOrganizationDetails(coaching?.orgId).swr;
  const isCoach = myId === coaching?.coachId;
  const hasEditAccess = store.auth.checkAccess(invoiceEditAccess);

  const { sumNet, sumGross, vatSums } = invoice;
  const vatPercentage = vatSums?.[0]?.vatPercent;

  const renderButtons = () => {
    const buttonStyle: ViewStyle = compactView ? { flexDirection: 'column' } : {};
    const wrapperStyle: ViewStyle = compactView ? { marginHorizontal: I18n.t('style.scheduledCalls.buttonMarginH') } : {};
    return (
      <View
        key={invoice.id}
        style={[styles.buttonContainer, compactView && styles.buttonContainerCompact]}
      >
        <ButtonSmall
          key={`${invoice.id}-invoice`}
          onPress={() => {
            onActionButtonPress(invoice, coaching as Coaching, 'View Invoice');
          }}
          title={I18n.t(`ui.buttons.${getButtonTitle(invoice.status, isCoach, hasEditAccess)}`)}
          icon={['accepted', 'paid'].includes(invoice.status) ? 'fileArrowDown' : 'Receipt'}
          isActive={isActive && selectedInvoice?.activeButton === 'View Invoice'}
          buttonStyle={buttonStyle}
          wrapperStyle={wrapperStyle}
          titleStyle={styles.buttonTitle}
          titleStyleCompact={styles.buttonTitleCompact}
        />
        <ButtonSmall
          key={`${invoice.id}-coaching`}
          onPress={() => onActionButtonPress(invoice, coaching as Coaching, 'View Coaching')}
          title={I18n.t('ui.buttons.viewCoaching')}
          icon="ChartLineUp"
          isActive={isActive && selectedInvoice?.activeButton === 'View Coaching'}
          buttonStyle={buttonStyle}
          wrapperStyle={wrapperStyle}
          titleStyle={styles.buttonTitle}
          titleStyleCompact={styles.buttonTitleCompact}
        />
      </View>
    );
  };

  const renderUserInfo = () => (
    <View style={[styles.cell, styles.userInfo]}>
      <UserInfo
        user={user}
        style={styles.userInfo}
        nameStyle={styles.userName}
      />
      {(!compactView || isActiveCellCompact) && (
        <Text numberOfLines={1} style={styles.infoText}>{program?.name}</Text>)}
      {(!compactView || isActiveCellCompact) && (
        <Text numberOfLines={1} style={styles.infoText}>{org?.name}</Text>)}
      {compactView && !isActiveCellCompact && (
        <Text numberOfLines={1} style={styles.infoTextLight}>
          {`${I18n.t('ui.invoices.amountTotal')} ${formatAmount(sumGross || sumNet)}`}
        </Text>
      )}
    </View>
  );

  const renderAmount = () => (
    <View style={[styles.cell, styles.amountCell]}>
      <View>
        <Text numberOfLines={1} style={styles.infoText}>{I18n.t('ui.invoices.amountNet')}</Text>
        {vatPercentage && (
          <Text numberOfLines={1} style={styles.infoText}>{I18n.t('ui.invoices.vat')}</Text>)}
        <Text numberOfLines={1} style={styles.infoText}>{I18n.t('ui.invoices.amountTotal')}</Text>
      </View>
      <View style={styles.amount}>
        <Text numberOfLines={1} style={styles.infoText}>{formatAmount(sumNet)}</Text>
        {vatPercentage && <Text numberOfLines={1} style={styles.infoText}>{`${vatPercentage}%`}</Text>}
        <Text numberOfLines={1} style={styles.infoText}>{formatAmount(sumGross || sumNet)}</Text>
      </View>
    </View>
  );

  const divider = () => {
    if (compactView && isActiveCellCompact) {
      return <View style={styles.dividerCompact} />;
    }
    if (!compactView) return <View style={styles.verticalDivider} />;
    return null;
  };

  return (
    <Hoverable key={`${invoice.id}-cell`}>
      {(isHovered) => (
        <View key={invoice.id}>
          <TouchableOpacity
            disabled={!compactView}
            onPress={() => {
              toggleActiveCell(invoice.id, isActiveCellCompact);
              resetOtherComponent(invoice.status);
            }}
            style={[
              styles.item,
              (isHovered && !compactView) && styles.itemHover,
              (isActive || isActiveCellCompact) && styles.itemActive,
              compactView && styles.itemCompact,
            ]}
          >
            <View
              style={[
                styles.initialInfo,
                compactView && styles.initialInfoCompact,
              ]}
            >
              <View style={styles.dateInfo}>
                {!isCoach && <Text numberOfLines={1} style={styles.userInfo}>{`${coach?.firstName || ''} ${coach?.lastName || ''}`}</Text>}
                <Text style={styles.date}>{formatDate('date.dayMonthYearShort', invoice.created)}</Text>
              </View>
              {!compactView && divider()}
              {renderUserInfo()}
              {divider()}
              {((compactView && isActiveCellCompact) || !compactView) && renderAmount()}
              {divider()}
            </View>
            {((isHovered && !compactView) || isActive || isActiveCellCompact) && (
              renderButtons()
            )}
          </TouchableOpacity>
          {!isLastRow && <View style={styles.itemDivider} />}
        </View>
      )}
    </Hoverable>
  );
});

export default InvoiceRow;

const styles = StyleSheet.create({
  item: {
    flexDirection: 'row',
    backgroundColor: Style.Color.White,
    justifyContent: 'space-between',
    borderLeftColor: Style.Color.Transparent,
    borderLeftWidth: 4,
    paddingLeft: 41,
    cursor: 'default',
    paddingVertical: 5,
  },
  itemCompact: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: 41,
    cursor: 'pointer',
  },
  itemHover: {
    borderLeftColor: Style.Color.Gray300,
  },
  itemActive: {
    borderLeftColor: Style.Color.Primary,
    borderLeftWidth: 4,
  },
  itemDivider: {
    borderBottomColor: Style.Color.Gray200,
    borderBottomWidth: 1,
    marginHorizontal: 28,
  },
  row: {
    flexDirection: 'row',
  },
  initialInfo: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  initialInfoCompact: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  date: {
    ...Style.Text.Caption,
    color: Style.Color.Black,
    paddingRight: 20,
  },
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 24,
  },
  buttonContainerCompact: {
    marginTop: 20,
    marginBottom: 6,
  },
  buttonTitle: {
    marginLeft: 0,
  },
  buttonTitleCompact: {
    marginLeft: 0,
  },
  userName: {
    ...Style.Text.Caption,
    color: Style.Color.Black,
  },
  userInfo: {
    paddingVertical: 5,
    width: 250,
  },
  infoText: {
    ...Style.Text.Normal,
    color: Style.Color.Black,
    maxWidth: 220,
    alignContent: 'center',
  },
  infoTextLight: {
    ...Style.Text.Normal,
    color: Style.Color.Gray400,
    maxWidth: 220,
    alignContent: 'center',
  },
  verticalDivider: {
    borderLeftColor: Style.Color.Gray200,
    borderLeftWidth: 1,
    height: 50,
    marginHorizontal: 20,
    alignSelf: 'center',
  },
  dividerCompact: {
    borderBottomColor: Style.Color.Gray200,
    borderBottomWidth: 1,
    width: 350,
    marginVertical: 20,
  },
  cell: {
    justifyContent: 'center',
  },
  amountCell: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  amount: {
    width: 140,
    alignItems: 'flex-end',
  },
  dateInfo: {
    width: 130,
  },
});
