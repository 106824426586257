import React, { useContext } from 'react';
import { StyleSheet, View } from 'react-native';
import { observer } from 'mobx-react-lite';

import { RootStoreContext } from '../stores/RootStore';
import Style from '../style';
import Section from './Section';
import ItemList, { Item } from './ItemList';
import Button from './Button';
import { fetchBillingDetailsList } from '../api/BillingDetails';
import I18n, { getCountryNames } from '../i18n';
import { getCountryInfo } from '../util/helpers';
import { sectionAccess } from '../navigation/AccessRules';

interface BIProps {
  openEditModal: (name: string) => void;
}

const BillingInfo = observer(({ openEditModal }: BIProps) => {
  const store = useContext(RootStoreContext);
  const myId = store.auth.userId;
  if (!store.auth.checkAccess(sectionAccess.BillingInfo) || !myId) return null;
  const { data: billings } = fetchBillingDetailsList(myId).swr;
  if (billings && 'httpStatus' in billings) {
    store.uiState.checkError(billings);
    return null;
  }
  const billing = billings?.[0];

  const countries = getCountryNames();
  const countryArr = Object.entries(countries);
  const country = billing ? getCountryInfo(countryArr, billing.country) : '';

  const iban: Item = {
    name: I18n.t('ui.billings.bankIban'),
    value: billing?.bankIban || '',
  };

  const vatOrTax: Item = {
    name: I18n.t(`ui.billings.${billing?.vatId ? 'vatId' : 'taxId'}`),
    value: billing?.vatId || billing?.taxId || '',
  };

  const company: Item = {
    name: I18n.t('ui.billings.companyName'),
    value: billing?.companyName || '',
  };

  const taxMode: Item = {
    name: I18n.t('ui.billings.taxMode.title'),
    value: billing?.taxMode ? I18n.t(`ui.billings.taxMode.${billing.taxMode}`) : '',
  };

  const address: Item = {
    name: I18n.t('ui.billings.address'),
    value: billing ? `${billing.firstName} ${billing.lastName}\n${billing.street}\n${billing.zipCode} ${billing.city}\n${billing.state ? ` ${billing.state},` : ''} ${country?.name || ''}` : '',
    valueStyle: { textAlign: 'right' },
  };

  const items = [
    iban,
    taxMode,
    ...(billing?.vatId || billing?.taxId ? [vatOrTax] : []),
    ...(billing?.companyName !== null ? [company] : []),
    address,
  ];

  return (
    <Section
      icon={Style.Icon.UserCircle}
      title={I18n.t('ui.billings.title')}
      key="billingInfo"
    >
      <ItemList items={items} />
      <View style={styles.buttonPad}>
        <Button
          btnStyle={styles.btnSave}
          btnStyleHovered={styles.btnSaveHovered}
          onPress={() => { openEditModal('BillingInfo'); }}
          title={I18n.t('ui.buttons.edit')}
          testId="BillingInfo.EditButton"
        />
      </View>
    </Section>
  );
});

export default BillingInfo;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignItems: 'center',
    flex: 1,
    paddingHorizontal: 20,
  },
  buttonPad: {
    marginTop: 40,
    marginBottom: 20,
    width: '100%',
    height: 40,
    alignItems: 'flex-end',
    paddingHorizontal: 20,
  },
  btnSave: {
    marginLeft: 4,
    width: 144,
  },
  btnSaveHovered: {
    marginLeft: 4,
    backgroundColor: Style.Color.Primary,
  },
});
