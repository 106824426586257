import * as yup from 'yup';

import { COUNTRY_RX, EMAIL_RX, IBAN_RX, NAME_RX, TAX_RX, VAT_RX, ZIPCODE_RX } from './regex';
import I18n from '../i18n';

// ref: https://github.com/jquense/yup/blob/master/src/locale.ts
yup.setLocale({
  string: {
    max: ({ max }) => I18n.t('error.maxAllowedChars', { max }),
  },
  mixed: {
    required: () => I18n.t('error.requiredField'),
  },
});

export const ProfileFormSchema = yup.object().shape({
  firstName: yup.string().required().matches(NAME_RX, I18n.t('error.pleaseEnterValidName')),
  lastName: yup.string(),
  gender: yup.string(),
  email: yup.string().email().required().matches(EMAIL_RX, I18n.t('error.login.pleaseEnterValidEmail')),
}).required();

export const BillingInfoFormSchema = yup.object({
  firstName: yup.string().required().max(100).matches(NAME_RX, I18n.t('error.pleaseEnterValidName')),
  lastName: yup.string().required().max(100).matches(NAME_RX, I18n.t('error.pleaseEnterValidName')),
  companyName: yup.string().max(100).nullable(),
  street: yup.string().max(200).required(),
  zipCode: yup.string().required().matches(ZIPCODE_RX, I18n.t('error.billings.pleaseEnterValidZipCode')),
  city: yup.string().max(100).required(),
  state: yup.string().max(100).nullable(),
  country: yup.string().default('DE').required().matches(COUNTRY_RX)
    .uppercase(),
  vatId: yup.string().nullable().notRequired().test('is-valid-vat', I18n.t('error.billings.pleaseEnterValidVatId'), (value) => {
    if (!value) return true;
    return VAT_RX.test(value);
  }),
  taxId: yup.string().nullable().notRequired().test('is-valid-tax', I18n.t('error.billings.pleaseEnterValidTaxId'), (value) => {
    if (!value) return true;
    return TAX_RX.test(value);
  }),
  taxMode: yup.string().default('taxable').required().oneOf(['taxable', 'nonTaxable']),
  bankIban: yup.string().required().matches(IBAN_RX, I18n.t('error.billings.pleaseEnterValidIban')),
}).required();

// To show vatOrTaxId error in vatId field.
// commenting because the error message does not go away when data is provided to any of this field,
// until submit button is pressed. which is kind of confusing
// .test(
//   'either-vatId-or-taxId',
//   I18n.t('error.billings.vatOrTax'),
//   function check(values) {
//     if (!!_.size(values.vatId) === !!_.size(values.taxId)) {
//       return this.createError({
//         message: I18n.t('error.billings.vatOrTax'),
//         path: 'vatId', // To show the error in the place of vatId field
//       });
//     }
//     return true;
//   },
// );

// .test({
//   name: 'either-vatId-or-taxId',
//   message: I18n.t('error.billings.vatOrTax'),
//   test:  function check(values) {
//     if (!!_.size(values.vatId) === !!_.size(values.taxId)) {
//       return this.createError({
//         message: I18n.t('error.billings.vatOrTax'),
//         path: 'vatId', // To show the error in the place of vatId field
//       });
//     }
//     return true;
//   },
//   params: { dependencies: ['vatId', 'taxId'] },
// });
